<template>
  <div class="container-fluid pt-5 pb-2 text-right scroll">
    <button @click="scrollToTop" class="btn scrollBtn">
      <i class="bi bi-chevron-double-up"></i>
    </button>
  </div>
</template>
<script>
export default {
  name: "scrollToTop",
  created() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      let currentScroll = document.documentElement.scrollTop,
        int = setInterval(frame, 6);
      function frame() {
        if (0 > currentScroll) clearInterval(int);
        else {
          currentScroll = currentScroll - 12;
          document.documentElement.scrollTop = currentScroll;
        }
      }
    },
  },
};
</script>
<style>
.scrollBtn {
  background-color: #e4772f;
  cursor: pointer;
}
.scrollBtn:hover {
  background: #e4772f;
}
.scrollBtn:hover i {
  color: #fff;
}
</style>
