<template>
  <div id="app">
    <div class="page-wrapper">
      <Header />
      <router-view />
      <Footer />
    </div>
  </div>
</template>
 
<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return { siteGenelBilgiler: {}, logo: {}, Keywords: [], keys: [] };
  },
  async created() {
    await this.$store.dispatch("GET_GENERAL_DATA");
    this.siteGenelBilgiler = this.$store.getters.GET_GENERAL_INFOS[0];
    this.logo = this.$store.getters.GET_GENERAL_INFOS[2];
    //document.title = this.siteGenelBilgiler.title;
    await this.$store.dispatch("GET_KEYWORDS_DATA");
    this.Keywords = this.$store.getters.GET_KEYWORDS;
    this.Keywords.forEach((elm) => {
      this.keys.push(elm.keyword);
    });
    await this.$store.dispatch("GET_SOCIAL_DATA");

    // short-cut site icon
    const icon = document.createElement("link");
    icon.rel = "shortcut icon";
    icon.type = "image/x-icon";
    icon.href = this.logo.logo;
    document.head.appendChild(icon);

    // site title
    // const title = document.createElement("title");
    //title.text = this.siteGenelBilgiler.title;
    //document.head.appendChild(title);

    // site keywords
    const meta = document.createElement("meta");
    meta.content = this.keys.toString();
    meta.name = "keywords";
    document.head.appendChild(meta);
  },
  methods: {},
};
</script>

<style>
</style>
