<template>
  <div class="container-fluid pt-1 pb-5">
    <div class="p-3">
      <AdminController />
    </div>
    <div
      class="nav flex-row justify-content-center nav-pills me-3"
      id="v-pills-tab"
      role="tablist"
      aria-orientation="vertical"
    >
      <button
        class="nav-link active"
        id="v-pills-home-tab"
        data-bs-toggle="pill"
        data-bs-target="#v-pills-home"
        type="button"
        role="tab"
        aria-controls="v-pills-home"
        aria-selected="true"
      >
        Genel Bilgiler
      </button>

      <button
        class="nav-link"
        id="v-pills-slider-tab"
        data-bs-toggle="pill"
        data-bs-target="#v-pills-slider"
        type="button"
        role="tab"
        aria-controls="v-pills-slider"
        aria-selected="true"
      >
        Slider
      </button>

      <button
        class="nav-link"
        id="v-pills-messages-tab"
        data-bs-toggle="pill"
        data-bs-target="#v-pills-messages"
        type="button"
        role="tab"
        aria-controls="v-pills-messages"
        aria-selected="false"
      >
        Hakkımızda
      </button>

      <button
        class="nav-link"
        id="v-pills-profile-tab"
        data-bs-toggle="pill"
        data-bs-target="#v-pills-profile"
        type="button"
        role="tab"
        aria-controls="v-pills-profile"
        aria-selected="false"
      >
        Ürünler
      </button>

      <button
        class="nav-link"
        id="v-pills-settings-tab"
        data-bs-toggle="pill"
        data-bs-target="#v-pills-settings"
        type="button"
        role="tab"
        aria-controls="v-pills-settings"
        aria-selected="false"
      >
        Resim Galeri
      </button>

      <button
        class="nav-link"
        id="v-pills-videos-tab"
        data-bs-toggle="pill"
        data-bs-target="#v-pills-videos"
        type="button"
        role="tab"
        aria-controls="v-pills-videos"
        aria-selected="false"
      >
        Video Galeri
      </button>

      <button
        class="nav-link"
        id="v-pills-references-tab"
        data-bs-toggle="pill"
        data-bs-target="#v-pills-references"
        type="button"
        role="tab"
        aria-controls="v-pills-references"
        aria-selected="false"
      >
        Referanslar
      </button>

      <button
        class="nav-link"
        id="v-pills-social-tab"
        data-bs-toggle="pill"
        data-bs-target="#v-pills-social"
        type="button"
        role="tab"
        aria-controls="v-pills-social"
        aria-selected="false"
      >
        Sosyal Medya
      </button>

      <button
        class="nav-link"
        id="v-pills-keywords-tab"
        data-bs-toggle="pill"
        data-bs-target="#v-pills-keywords"
        type="button"
        role="tab"
        aria-controls="v-pills-keywords"
        aria-selected="false"
      >
        Anahtar Kelimeler
      </button>
    </div>
    <div
      class="
        d-flex
        align-items-start
        justify-content-center
        col-lg-12
        mt-5
        pt-2
      "
    >
      <div class="tab-content container" id="v-pills-tabContent">
        <!-- THIS SECTION IS General INFOS START -->
        <div
          class="tab-pane fade show active"
          id="v-pills-home"
          role="tabpanel"
          aria-labelledby="v-pills-home-tab"
        >
          <div class="row w-100">
            <div class="col col-lg-6">
              <form
                @submit.prevent="WEB_GENERAL_INFOS"
                style="display: flex; flex-direction: column"
              >
                <div class="input-div">
                  <label for="webtitle">Site Başlığı:</label>
                  <input
                    class="input"
                    type="text"
                    placeholder="title"
                    id="webtitle"
                    v-model="siteBilgileri.title"
                  />
                </div>

                <div class="input-div">
                  <label for="webphone">İletişim Numarası:</label>
                  <input
                    class="input"
                    type="text"
                    placeholder="phone"
                    id="webphone"
                    v-model="siteBilgileri.phone"
                  />
                </div>
                <div class="input-div">
                  <label for="webmail">Mail Adresi:</label>
                  <input
                    class="input"
                    type="text"
                    placeholder="mail"
                    id="webmail"
                    v-model="siteBilgileri.mail"
                  />
                </div>
                <div class="input-div">
                  <label for="webadres">İşletme Adresi:</label>
                  <input
                    class="input"
                    type="text"
                    placeholder="address"
                    id="webadres"
                    v-model="siteBilgileri.address"
                    value="dscfd"
                  />
                </div>

                <button class="btn btn-outline-primary p-2 mt-3">
                  Genel Bilgileri Güncelle
                </button>
              </form>
            </div>

            <div class="col col-lg-6">
              <label for="weblogo">Logo</label>
              <input
                id="weblogo"
                class="form-control"
                type="file"
                @change="handleFileLogo"
              />
              <button
                class="btn btn-outline-primary p-4 mt-1"
                @click="UPDATE_LOGO"
              >
                Logoyu Güncelle
              </button>
              <img
                class="p-5 mt-2"
                :src="this.logo.logo"
                alt="güncel logo"
                width="200px"
              />
            </div>
          </div>
        </div>
        <!-- THIS SECTION IS General End -->

        <!-- THIS SECTION IS SLIDER START -->
        <div
          class="tab-pane fade"
          id="v-pills-slider"
          role="tabpanel"
          aria-labelledby="v-pills-slider-tab"
        >
          <div class="container pb-5">
            <div class="my-5 text-center">
              <button
                @click="showSlideModal = true"
                class="btn btn-outline-primary mb-5"
              >
                Slide Ekle
              </button>
              <form
                class="modals"
                v-if="showSlideModal == true"
                @submit="CREATE_SLIDER"
              >
                <div class="modals-div">
                  <span @click="showSlideModal = false">
                    <i
                      style="
                        font-size: 50px;
                        position: absolute;
                        right: 80px;
                        top: 20px;
                        z-index: 99999;
                        cursor: pointer;
                      "
                      class="fa fa-close"
                    ></i>
                  </span>
                  <div class="my-3">
                    <label for="validationTextarea" class="form-label"
                      >Slide Detayları</label
                    >
                    <textarea
                      class="form-control"
                      placeholder="Başlık girin"
                      v-model="newSlider.slideTitle"
                    ></textarea>
                    <VueEditor
                      class="input mt-2"
                      v-model="newSlider.slideDescription"
                    />
                    <textarea
                      class="form-control mt-2"
                      placeholder="link ekleyin"
                      v-model="newSlider.slideLink"
                    ></textarea>
                    <div class="input-group mt-2 mb-2">
                      <span class="text-center d-flex align-items-center"
                        >Kapak Resmi Seçin:&nbsp;</span
                      >
                      <input
                        class="form-control"
                        type="file"
                        @change="handleFileSlide"
                      />
                    </div>
                    <button type="submit" class="btn btn-outline-primary">
                      Paylaş</button
                    ><button
                      @click="showSlideModal = false"
                      class="btn btn-outline-warning ml-1"
                    >
                      Kapat
                    </button>
                  </div>
                  <span
                    style="font-size: 30px; color: red; padding: 15px 0 0 25px"
                    >{{ this.progres }}</span
                  >
                </div>
              </form>
              <form class="modals" v-if="updateSlideModal == true">
                <div class="modals-div">
                  <span @click="updateSlideModal = false">
                    <i
                      style="
                        font-size: 50px;
                        position: absolute;
                        right: 80px;
                        top: 20px;
                        z-index: 99999;
                        cursor: pointer;
                      "
                      class="fa fa-close"
                    ></i>
                  </span>
                  <div class="my-3">
                    <label for="validationTextarea" class="form-label"
                      >Slide Detayları</label
                    >
                    <textarea
                      class="form-control"
                      placeholder="Başlık girin"
                      v-model="newSlider.slideTitle"
                    ></textarea>
                    <VueEditor
                      class="input mt-2"
                      v-model="newSlider.slideDescription"
                    />
                    <textarea
                      class="form-control mt-2"
                      placeholder="link ekleyin"
                      v-model="newSlider.slideLink"
                    ></textarea>
                    <div class="input-group mt-2 mb-2">
                      <span class="text-center d-flex align-items-center"
                        >Kapak Resmi Seçin:&nbsp;</span
                      >
                      <input
                        class="form-control"
                        type="file"
                        @change="handleFileSlide"
                      />
                    </div>
                    <div class="text-left mt-2 pt-2">
                      <img
                        style="width: 80px"
                        :src="newSlider.slideImage"
                        alt=""
                      />
                    </div>
                    <button
                      @click="UPDATE_SLIDER"
                      class="btn btn-outline-primary"
                    >
                      Güncelle</button
                    ><button
                      @click="updateSlideModal = false"
                      class="btn btn-outline-warning ml-1"
                    >
                      Kapat
                    </button>
                  </div>
                  <span
                    style="font-size: 30px; color: red; padding: 15px 0 0 25px"
                    >{{ this.progres }}</span
                  >
                </div>
              </form>

              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Görsel</th>
                    <th scope="col">İsim</th>
                    <th scope="col">Güncelle</th>
                    <th scope="col">Sil</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in Slides" :key="item.slideId">
                    <td>
                      <img
                        style="height: 50px; width: 60px"
                        :src="item.slideImage"
                        alt=""
                      />
                    </td>
                    <td>{{ item.slideTitle }}</td>
                    <td>
                      <span
                        style="cursor: pointer"
                        @click="(updateSlideModal = true), (newSlider = item)"
                        ><i class="fa fa-pen-to-square"></i
                      ></span>
                    </td>
                    <td>
                      <span style="cursor: pointer" @click="DELETE_SLIDE(item)">
                        <i class="fa fa-trash"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Scroll />
          </div>

          <!-- slide list -->
        </div>
        <!-- THIS SECTION IS SLIDER END -->

        <!-- THIS SECTION IS ABOUT US START -->
        <div
          class="tab-pane fade"
          id="v-pills-messages"
          role="tabpanel"
          aria-labelledby="v-pills-messages-tab"
        >
          <div class="col-lg-12 col">
            <label for="hakkimizda">Hakkımızda Yazısı Girin:</label>
            <VueEditor class="input" v-model="aboutUs.about" />
            <button
              class="btn btn-outline-primary mt-2"
              @click="UPDATE_ABOUT_US"
            >
              Yazıyı Kaydet
            </button>
          </div>
        </div>
        <!-- THIS SECTION IS Ge ABOUT US End -->

        <!-- THIS SECTION IS Product START -->
        <div
          class="tab-pane fade"
          id="v-pills-profile"
          role="tabpanel"
          aria-labelledby="v-pills-profile-tab"
        >
          <div class="container pt-1">
            <div class="text-center">
              <button
                @click="showModal = true"
                class="btn btn-outline-primary mb-5"
              >
                Ürün Ekle
              </button>

              <form class="modals" v-if="showModal == true">
                <div class="modals-div modals-div-update">
                  <span @click="showModal = false">
                    <i
                      style="
                        font-size: 50px;
                        position: absolute;
                        right: 50px;
                        top: 20px;
                        z-index: 999999;
                        cursor: pointer;
                      "
                      class="fa fa-close"
                    ></i>
                  </span>
                  <div class="my-3 form-div pt-150">
                    <label for="validationTextarea" class="form-label"
                      >Ürün Detayları</label
                    >
                    <label class="text-left" for="baslik">Ürün Adı:</label>
                    <textarea
                      class="form-control"
                      id="baslik"
                      placeholder="Please enter a title"
                      v-model="newProduct.productTitle"
                    ></textarea>
                    <label class="text-left mt-3" for="aciklama"
                      >Ürün Açıklaması:</label
                    >
                    <VueEditor
                      id="aciklama"
                      class="input"
                      v-model="newProduct.productDescription"
                    />
                    <div class="input-group pt-3">
                      <div class="w-100">
                        <label
                          for="product-image"
                          class="text-center d-flex align-items-center"
                          >Kapak Resmi Seçin:</label
                        >
                        <input
                          class="form-control"
                          id="product-image"
                          type="file"
                          @change="handleFile"
                        />
                        <div class="text-left mt-2 pt-2">
                          <img
                            class="w-25"
                            :src="newProduct.display"
                            :alt="newProduct.display"
                          />
                        </div>
                        <div class="text-left mt-2 pt-2">
                          <img
                            style="width: 80px"
                            :src="newProduct.productImage"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="w-100 pt-2 d-flex row">
                        <label class="text-left" for="images"
                          >Ürün Görselleri Seçin</label
                        >
                        <input
                          id="images"
                          class="form-control"
                          type="file"
                          @change="handleFileProductImages"
                        />
                        <div class="d-flex w-100">
                          <div
                            style="width: 80px; height: 70px; padding: 5px"
                            v-for="image in newProduct.productImages"
                            :key="image"
                          >
                            <div class="row">
                              <img
                                class="col"
                                :src="image"
                                alt=""
                                style="
                                  width: 80px;
                                  height: 70px;
                                  cursor: pointer;
                                "
                                @click="DELETE_PRODUCT_IMAGE(image)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3 mb-3">
                    <button
                      v-if="productUpdateModal == false"
                      type="submit"
                      class="btn btn-outline-primary"
                      @click="CREATE_PRODUCT"
                    >
                      Paylaş
                    </button>

                    <button
                      @click="showModal = false"
                      class="btn btn-outline-warning ml-1"
                    >
                      Kapat
                    </button>
                  </div>

                  <span
                    style="font-size: 30px; color: red; padding: 15px 0 0 25px"
                    >{{ this.progres }}</span
                  >
                </div>
              </form>

              <form class="modals" v-if="productUpdateModal == true">
                <div class="modals-div modals-div-update">
                  <span @click="productUpdateModal = false">
                    <i
                      style="
                        font-size: 50px;
                        position: absolute;
                        right: 50px;
                        top: 20px;
                        z-index: 999999;
                        cursor: pointer;
                      "
                      class="fa fa-close"
                    ></i>
                  </span>
                  <div class="my-3 form-div pt-150">
                    <label for="validationTextarea" class="form-label"
                      >Ürün Detayları</label
                    >
                    <label class="text-left" for="baslik">Ürün Adı:</label>
                    <textarea
                      class="form-control"
                      id="baslik"
                      placeholder="Please enter a title"
                      v-model="newProduct.productTitle"
                    ></textarea>
                    <label class="text-left mt-3" for="aciklama"
                      >Ürün Açıklaması:</label
                    >
                    <VueEditor
                      id="aciklama"
                      class="input"
                      v-model="newProduct.productDescription"
                    />
                    <div class="input-group pt-3">
                      <div class="w-100">
                        <label
                          for="product-image"
                          class="text-center d-flex align-items-center"
                          >Kapak Resmi Seçin:</label
                        >
                        <input
                          class="form-control"
                          id="product-image"
                          type="file"
                          @change="handleFile"
                        />
                        <div class="text-left mt-2 pt-2">
                          <img
                            class="w-25"
                            :src="newProduct.display"
                            :alt="newProduct.display"
                          />
                        </div>
                        <div class="text-left mt-2 pt-2">
                          <img
                            style="width: 80px"
                            :src="newProduct.productImage"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="w-100 pt-2 d-flex row">
                        <label class="text-left" for="images"
                          >Ürün Görselleri Seçin</label
                        >
                        <input
                          id="images"
                          class="form-control"
                          type="file"
                          @change="handleFileProductImages"
                        />
                        <div class="d-flex w-100">
                          <div
                            style="width: 80px; height: 70px; padding: 5px"
                            v-for="image in newProduct.productImages"
                            :key="image"
                          >
                            <div class="row">
                              <img
                                class="col"
                                :src="image"
                                alt=""
                                style="
                                  width: 80px;
                                  height: 70px;
                                  cursor: pointer;
                                "
                                @click="DELETE_PRODUCT_IMAGE(image)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3 mb-3">
                    <button
                      type="submit"
                      class="btn btn-outline-primary"
                      @click="UPDATE_PRODUCT"
                    >
                      Güncelle
                    </button>

                    <button
                      @click="productUpdateModal = false"
                      class="btn btn-outline-warning ml-1"
                    >
                      Kapat
                    </button>
                  </div>

                  <span
                    style="font-size: 30px; color: red; padding: 15px 0 0 25px"
                    >{{ this.progres }}</span
                  >
                </div>
              </form>
              <!-- update table -->

              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Görsel</th>
                    <th scope="col">İsim</th>
                    <th scope="col">Güncelle</th>
                    <th scope="col">Sil</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in Products" :key="item.productId">
                    <td>
                      <img
                        style="height: 60px; weight: 60px"
                        :src="item.productImage"
                        alt=""
                      />
                    </td>
                    <td>{{ item.productTitle }}</td>
                    <td>
                      <span
                        style="cursor: pointer"
                        @click="
                          (showModal = true),
                            (productUpdateModal = true),
                            (newProduct = item)
                        "
                        ><i class="fa fa-pen-to-square"></i
                      ></span>
                    </td>
                    <td>
                      <span
                        style="cursor: pointer"
                        @click="DELETE_PRODUCT(item)"
                      >
                        <i class="fa fa-trash"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Scroll />
          </div>

          <!-- product list -->
        </div>
        <!-- THIS SECTION IS Product END -->

        <!-- THIS SECTION IS IMAGE GALLERY Start -->
        <div
          class="tab-pane fade"
          id="v-pills-settings"
          role="tabpanel"
          aria-labelledby="v-pills-settings-tab"
        >
          <form @submit="SAVE_IMAGE_GALLERY">
            <div>
              <div class="mb-3">
                <div class="input-group mt-2">
                  <span class="text-center d-flex align-items-center">
                    Resim Seçin:&nbsp;</span
                  >

                  <input
                    class="form-control"
                    v-on:change="handleFileImageGallery"
                    type="file"
                  />
                </div>
              </div>
              <button class="btn btn-outline-primary">Ekle</button>
              <span
                style="font-size: 30px; color: red; padding: 15px 0 0 25px"
                >{{ this.progres }}</span
              >
            </div>
          </form>
          <hr />
          <div class="row">
            <span class="w-100 py-1" style="font-size: 12px">
              <span style="color: red">*</span> silmek için resme
              tıklayın!</span
            >
            <br />
            <div
              v-for="img in galleryImages"
              :key="img.imageId"
              style="padding: 0px"
            >
              <img
                :src="img.imageUrl"
                alt=""
                style="
                  width: 200px;
                  height: 150px;
                  border-radius: 12px;
                  padding: 5px;
                  cursor: pointer;
                "
                @click="DELETE_IMAGE(img)"
              />
            </div>
          </div>
        </div>
        <!-- THIS SECTION IS IMAGE GALLERY END -->

        <!-- THIS SECTION IS KEYS Start -->
        <div
          class="tab-pane fade"
          id="v-pills-videos"
          role="tabpanel"
          aria-labelledby="v-pills-videos-tab"
        >
          <form @submit="SAVE_VIDEO">
            <div>
              <div class="mb-3">
                <div class="input-group mt-2">
                  <span class="text-center d-flex align-items-center">
                    Youtube Link Girin:&nbsp;</span
                  ><input
                    class="form-control"
                    type="text"
                    v-model="videoLink"
                  />
                </div>
              </div>
              <button class="btn btn-outline-primary">Ekle</button>
              <span>{{ this.progres }}</span>
            </div>
          </form>
          <hr />
          <div class="row">
            <span class="w-100 py-1" style="font-size: 12px">
              <span style="color: red">*</span> silmek için linke
              tıklayın!</span
            >
            <br />
            <div
              v-for="video in Videos"
              :key="video.videoId"
              style="margin: 5px; cursor: pointer"
            >
              <p
                style="
                  padding: 4px;
                  border-radius: 6px;
                  background-color: antiquewhite;
                "
                @click="DELETE_VIDEO(video)"
              >
                {{ video.videoLink }}
              </p>
            </div>
          </div>
        </div>
        <!-- THIS SECTION IS KEYS END  -->

        <!-- THIS SECTION IS REFERENCES Start -->
        <div
          class="tab-pane fade"
          id="v-pills-references"
          role="tabpanel"
          aria-labelledby="v-pills-references-tab"
        >
          <form @submit="SAVE_REFERENCE">
            <div>
              <div class="mb-3">
                <div class="input-group mt-2">
                  <span class="text-center d-flex align-items-center">
                    Referans Girin:&nbsp;</span
                  ><input
                    class="form-control"
                    type="text"
                    v-model="reference"
                  />
                </div>
              </div>
              <button class="btn btn-outline-primary">Ekle</button>
              <span>{{ this.progres }}</span>
            </div>
          </form>
          <hr />
          <div class="row">
            <span class="w-100 py-1" style="font-size: 12px">
              <span style="color: red">*</span> silmek için referans adına
              tıklayın!</span
            >
            <br />
            <div
              v-for="ref in References"
              :key="ref.referenceId"
              style="margin: 5px; cursor: pointer"
            >
              <p
                style="
                  padding: 4px;
                  border-radius: 6px;
                  background-color: antiquewhite;
                "
                @click="DELETE_REFERENCE(ref)"
              >
                {{ ref.reference }}
              </p>
            </div>
          </div>
        </div>
        <!-- THIS SECTION IS REFERENCES Start  -->

        <!-- THIS SECTION IS SOCIAL START -->
        <div
          class="tab-pane fade show"
          id="v-pills-social"
          role="tabpanel"
          aria-labelledby="v-pills-social-tab"
        >
          <div class="row w-100">
            <div class="col">
              <form @submit.prevent="SAVE_SOCIAL_MEDIA">
                <div class="input-div">
                  <label for="face">Facebook:</label>
                  <input
                    class="input"
                    type="text"
                    placeholder="facebook"
                    id="face"
                    v-model="social.facebook"
                  />
                </div>

                <div class="input-div">
                  <label for="insta">Instagram:</label>
                  <input
                    class="input"
                    type="text"
                    placeholder="instagram"
                    id="insta"
                    v-model="social.instagram"
                  />
                </div>
                <div class="input-div">
                  <label for="twit">Twitter</label>
                  <input
                    class="input"
                    type="text"
                    placeholder="twitter"
                    id="twit"
                    v-model="social.twitter"
                  />
                </div>
                <button class="btn btn-outline-primary p-2 mt-3">
                  Güncelle
                </button>
              </form>
            </div>
          </div>
        </div>
        <!-- THIS SECTION IS SOCIAL End -->

        <!-- THIS SECTION IS KEYS Start -->
        <div
          class="tab-pane fade"
          id="v-pills-keywords"
          role="tabpanel"
          aria-labelledby="v-pills-keywords-tab"
        >
          <form @submit="SAVE_KEYWORD">
            <div>
              <div class="mb-3">
                <div class="input-group mt-2">
                  <span class="text-center d-flex align-items-center">
                    Anahtar Kelime Girin:&nbsp;</span
                  ><input class="form-control" type="text" v-model="keyword" />
                </div>
              </div>
              <button class="btn btn-outline-primary">Ekle</button>
              <span>{{ this.progres }}</span>
            </div>
          </form>
          <hr />
          <div class="row">
            <span class="w-100 py-1" style="font-size: 12px">
              <span style="color: red">*</span> silmek için anahtar kelimeye
              tıklayın!</span
            >
            <br />
            <div
              v-for="key in Keywords"
              :key="key.keywordId"
              style="margin: 5px; cursor: pointer"
            >
              <p
                style="
                  padding: 4px;
                  border-radius: 6px;
                  background-color: antiquewhite;
                "
                @click="DELETE_KEYWORD(key)"
              >
                {{ key.keyword }}
              </p>
            </div>
          </div>
        </div>
        <!-- THIS SECTION IS KEYS END  -->
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../../firebase/firebase";
let db = firebase.db;
let storage = firebase.storage;
import AdminController from "@/components/AdminController.vue";

export default {
  data() {
    return {
      logo: "",
      aboutUs: {
        about: "",
      },
      siteBilgileri: {
        title: "",
        phone: "",
        mail: "",
        address: "",
      },
      showSlideModal: false,
      updateSlideModal: false,
      newSlider: {
        slideTitle: "",
        slideDescription: "",
        slideLink: "",
        slideImage: "",
      },
      Slides: [],
      showModal: false,
      productUpdateModal: false,
      Products: [],
      newProduct: {
        display: "kapak resmi seçilmedi",
        productTitle: "",
        productDescription: "",
        productImage: "",
        productImages: [],
      },
      content: "",
      progres: "",
      images: {
        imageUrl: "",
      },
      galleryImages: [],
      videoLink: "",
      Videos: [],
      keyword: "",
      Keywords: [],
      reference: "",
      References: [],
      social: {
        facebook: "",
        twitter: "",
        instagram: "",
      },
    };
  },
  components: {
    AdminController,
  },
  gelenData: [],

  async created() {
    await this.$store.dispatch("GET_GENERAL_DATA");
    await this.$store.dispatch("GET_PRODUCTS_DATA");
    await this.$store.dispatch("GET_IMAGE_DATA");
    await this.$store.dispatch("GET_SLIDE_DATA");
    await this.$store.dispatch("GET_KEYWORDS_DATA");
    await this.$store.dispatch("GET_REFERENCE_DATA");
    await this.$store.dispatch("GET_VIDEO_DATA");
    await this.$store.dispatch("GET_SOCIAL_DATA");
    this.gelenData = this.$store.getters.GET_GENERAL_INFOS;
    this.siteBilgileri = this.gelenData[0];
    this.logo = this.gelenData[2];
    this.aboutUs = this.gelenData[1];
    this.Products = this.$store.getters.GET_PRODUCTS;
    this.galleryImages = this.$store.getters.GET_IMAGES;
    this.Slides = this.$store.getters.GET_SLIDE;
    this.Keywords = this.$store.getters.GET_KEYWORDS;
    this.References = this.$store.getters.GET_REFERENCES;
    this.Videos = this.$store.getters.GET_VIDEO;
    this.social = this.$store.getters.GET_SOCIAL[0];
  },

  methods: {
    //##################################################### GENERAL INFOS START ####################################
    async WEB_GENERAL_INFOS(e) {
      e.preventDefault();
      let title = this.siteBilgileri.title;
      let phone = this.siteBilgileri.phone;
      let mail = this.siteBilgileri.mail;
      let address = this.siteBilgileri.address;

      if (title == "" || null) {
        alert("Lütfen Tüm Alanları Doldurun!");
      } else {
        let id = Date.now().toString();
        const genelBilgiler = {
          title,
          phone,
          mail,
          address,
          generalId: id,
          name: "general infos",
        };
        await this.$store.dispatch("POST_GENERAL_INFOS", genelBilgiler);
      }
    },
    handleFileLogo(e) {
      this.logo = e.target.files[0];
    },
    async UPDATE_LOGO() {
      let id = Date.now().toString();
      let url = await this.handleFileUpload(this.logo, "Logo");
      let fileRef = await firebase.storage.refFromURL(url);
      const logo = {
        logo: url,
        logoRef: fileRef.fullPath,
        logoId: id,
        name: "logo",
      };
      await this.$store.dispatch("POST_LOGO", logo);
    },
    //##################################################### GENERAL INFOS END ####################################

    //##################################################### SLIDER CREATE SECTION START ####################################
    handleFileSlide(e) {
      this.newSlider.slideImage = e.target.files[0];
    },
    async CREATE_SLIDER() {
      let slideTitle = this.newSlider.slideTitle;
      let slideDescription = this.newSlider.slideDescription;
      let slideLink = this.newSlider.slideLink;
      if (slideTitle == "" || slideDescription == "") {
        alert("Lütfen Tüm Alanları Doldurun!");
      } else {
        let id = Date.now().toString();
        let url = await this.handleFileUpload(
          this.newSlider.slideImage,
          "Slide"
        );
        let fileRef = await firebase.storage.refFromURL(url);
        const slide = {
          slideTitle,
          slideDescription,
          slideLink: slideLink ? slideLink : "#",
          slideImage: url,
          slideImageRef: fileRef.fullPath,
          slideId: id,
        };
        await this.$store.dispatch("POST_SLIDE", slide);
        this.newSlider = {};
        this.progress = "";
        this.showSlideModal = false;
        await this.$store.dispatch("GET_SLIDE_DATA");
        this.Slides = this.$store.getters.GET_SLIDE;
      }
    },
    //##################################################### SLIDER CREATE SECTION END ####################################

    //##################################################### SLIDER UPDATE SECTION START ####################################

    async UPDATE_SLIDER() {
      let slideTitle = this.newSlider.slideTitle;
      let slideDescription = this.newSlider.slideDescription;
      let slideLink = this.newSlider.slideLink;
      if (this.newSlider.slideImage.length > 38) {
        if (this.newSlider.slideImage.split("://")[0] == "https") {
          const slide = {
            slideTitle,
            slideDescription,
            slideLink: slideLink ? slideLink : "#",
            slideImage: this.newSlider.slideImage,
            slideImageRef: this.newSlider.slideImageRef,
            slideId: this.newSlider.slideId,
          };
          await this.$store.dispatch("UPDATE_SLIDE", slide);
          this.newSlider = {};
          this.updateSlideModal = false;
          await this.$store.dispatch("GET_SLIDE_DATA");
          this.Slides = this.$store.getters.GET_SLIDE;
        }
      } else {
        let url = await this.handleFileUpload(
          this.newSlider.slideImage,
          "Slide"
        );
        let fileRef = await firebase.storage.refFromURL(url);
        const slide = {
          slideTitle,
          slideDescription,
          slideLink: slideLink ? slideLink : "#",
          slideImage: url,
          slideImageRef: fileRef.fullPath,
          slideId: this.newSlider.slideId,
        };
        await this.$store.dispatch("UPDATE_SLIDE", slide);
        this.newSlider = {};
        this.progress = "";
        this.updateSlideModal = false;
        await this.$store.dispatch("GET_SLIDE_DATA");
        this.Slides = this.$store.getters.GET_SLIDE;
      }
    },
    //##################################################### SLIDER UPDATE SECTION END ####################################

    //##################################################### SLIDER DELETE START ####################################

    async DELETE_SLIDE(slide) {
      let value = confirm(
        slide.slideTitle + " Adlı Slide Silmek İstediğinizden Emin Misiniz? "
      );
      if (value === true) {
        await storage.ref(slide.slideImageRef).delete();
        await db.collection("Slide").doc(slide.slideId).delete();
      }
      await this.$store.dispatch("GET_SLIDE_DATA");
      this.Slides = this.$store.getters.GET_SLIDE;
    },
    //##################################################### SLIDER DELETE END ####################################

    //##################################################### SLIDER SECTION END ####################################

    //##################################################### ABOUT US START ####################################
    async UPDATE_ABOUT_US() {
      let about = this.aboutUs.about;
      if (about == "") {
        alert("Lütfen Hakkımızda Yazısı Ekleyin!");
      } else {
        let id = Date.now().toString();
        const a = {
          id,
          about,
          name: "about us",
        };
        await this.$store.dispatch("UPDATE_ABOUT_US", a);
      }
    },
    //##################################################### ABOUT US END ####################################

    //##################################################### PRODUCT CREATE START ####################################

    handleFile(e) {
      this.newProduct.productImage = e.target.files[0];
      this.newProduct.display =
        e.target.files[0].name + " adlı görsel seçildi!";
    },
    // Handle file upload for every img
    async handleFileUpload(file, e) {
      // aynı referansa sahip görseller için id ile ayrıştırma yapıldı
      let id = Date.now().toString();
      let d;
      const storageRef = storage.ref(e);
      const storageChild = storageRef.child(id + "/" + file.name);
      const postProductImage = storageChild.put(file);
      await new Promise((resolve) => {
        postProductImage.on(
          "state_changed",
          (snapshot) => {
            let progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.progres = Math.trunc(progress);
          },
          (error) => {
            console.log(error);
          },
          async () => {
            const downloadUrl = await storageChild.getDownloadURL();
            d = downloadUrl;
            resolve();
          }
        );
      });
      return d;
    },

    //MULTIPLE PRODUCT IMAGES
    async handleFileProductImages(e) {
      let id = Date.now().toString();
      let file = e.target.files[0];
      const storageRef = storage.ref("Products");
      const storageChild = storageRef.child(id + "+" + file.name);
      const postProductImage = storageChild.put(file);
      await new Promise((resolve) => {
        postProductImage.on(
          "state_changed",
          (snapshot) => {
            let progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.progres = Math.trunc(progress);
          },
          (error) => {
            console.log(error);
          },
          async () => {
            const downloadUrl = await storageChild.getDownloadURL();
            this.newProduct.productImages.push(downloadUrl);
            resolve();
          }
        );
      });
    },

    //CREATE PRODUCT
    async CREATE_PRODUCT(e) {
      e.preventDefault();
      let id = Date.now().toString();
      let url = await this.handleFileUpload(
        this.newProduct.productImage,
        "Products"
      );
      let fileRef = await firebase.storage.refFromURL(url);
      let lowerUrl = this.newProduct.productTitle.toLowerCase();
      //console.log(lowerUrl);
      String.prototype.turkishtoEnglish = function () {
        return this.replace("Ğ", "g")
          .replace("ı", "i")
          .replace("Ü", "u")
          .replace("Ş", "s")
          .replace("I", "i")
          .replace("İ", "i")
          .replace("Ö", "o")
          .replace("Ç", "c")
          .replace("ğ", "g")
          .replace("ü", "u")
          .replace("ş", "s")
          .replace("ö", "o")
          .replace("ç", "c");
      };
      //    console.log(lowerUrl.turkishtoEnglish());
      let text = "";
      for (const harf of lowerUrl) {
        text += harf
          .split("ı")
          .join("i")
          .split("İ")
          .join("I")
          .split("?")
          .join("")
          .toLowerCase()
          .replace("ğ", "g")
          .replace("ç", "c")
          .replace("ü", "u")
          .replace("ö", "o")
          .replace("ş", "s");
      }
      let trim = text.trim();
      let newText = trim.split(" ").join("-");
      const product = {
        productTitle: this.newProduct.productTitle,
        productDescription: this.newProduct.productDescription,
        productImage: url,
        fileref: fileRef.fullPath,
        productPath: newText,
        productImages: this.newProduct.productImages,
        productId: id,
        display: this.newProduct.display,
      };
      await this.$store.dispatch("CREATE_PRODUCT", product);
      this.newProduct.display = "kapak resmi seçilmedi";
      this.newProduct.productTitle = "";
      this.newProduct.productDescription = "";
      this.newProduct.productImage = "";
      this.newProduct.productImages = [];
      this.showModal = false;
      this.progress = "";
      await this.$store.dispatch("GET_PRODUCTS_DATA");
      this.Products = this.$store.getters.GET_PRODUCTS;
    },
    //################################################### PRODUCT CREATE END ####################

    //##################################################### PRODUCT UPDATE START ####################################
    async UPDATE_PRODUCT(e) {
      e.preventDefault();
      if (this.newProduct.productImage.length > 38) {
        if (this.newProduct.productImage.split("://")[0] == "https") {
          let lowerUrl = this.newProduct.productTitle.toLowerCase();
          String.prototype.turkishtoEnglish = function () {
            return this.replace("Ğ", "g")
              .replace("ı", "i")
              .replace("Ü", "u")
              .replace("Ş", "s")
              .replace("I", "i")
              .replace("İ", "i")
              .replace("Ö", "o")
              .replace("Ç", "c")
              .replace("ğ", "g")
              .replace("ü", "u")
              .replace("ş", "s")
              .replace("ö", "o")
              .replace("ç", "c");
          };
          console.log(lowerUrl.turkishtoEnglish());
          let text = "";
          for (const harf of lowerUrl) {
            text += harf
              .split("ı")
              .join("i")
              .split("İ")
              .join("I")
              .split("?")
              .join("")
              .toLowerCase()
              .replace("ğ", "g")
              .replace("ç", "c")
              .replace("ü", "u")
              .replace("ö", "o")
              .replace("ş", "s");
          }
          let trim = text.trim();
          let newText = trim.split(" ").join("-");
          const product = {
            productTitle: this.newProduct.productTitle,
            productDescription: this.newProduct.productDescription,
            productImage: this.newProduct.productImage,
            fileref: this.newProduct.fileref,
            productPath: newText,
            productId: this.newProduct.productId,
            productImages: this.newProduct.productImages,
          };
          await this.$store.dispatch("UPDATE_PRODUCT", product);
          this.newProduct.display = "kapak resmi seçilmedi";
          this.newProduct.productTitle = "";
          this.newProduct.productDescription = "";
          this.newProduct.productImage = "";
          this.newProduct.productImages = [];
          this.productUpdateModal = false;
          this.progress = "";
          await this.$store.dispatch("GET_PRODUCTS_DATA");
          this.Products = this.$store.getters.GET_PRODUCTS;
        }
      } else {
        let url = await this.handleFileUpload(
          this.newProduct.productImage,
          "Products"
        );
        let fileRef = await firebase.storage.refFromURL(url);
        let lowerUrl = this.newProduct.productTitle.toLowerCase();
        console.log(lowerUrl);
        String.prototype.turkishtoEnglish = function () {
          return this.replace("Ğ", "g")
            .replace("ı", "i")
            .replace("Ü", "u")
            .replace("Ş", "s")
            .replace("I", "i")
            .replace("İ", "i")
            .replace("Ö", "o")
            .replace("Ç", "c")
            .replace("ğ", "g")
            .replace("ü", "u")
            .replace("ş", "s")
            .replace("ö", "o")
            .replace("ç", "c");
        };
        console.log(lowerUrl.turkishtoEnglish());
        let text = "";
        for (const harf of lowerUrl) {
          text += harf
            .split("ı")
            .join("i")
            .split("İ")
            .join("I")
            .split("?")
            .join("")
            .toLowerCase()
            .replace("ğ", "g")
            .replace("ç", "c")
            .replace("ü", "u")
            .replace("ö", "o")
            .replace("ş", "s");
        }
        let trim = text.trim();
        let newText = trim.split(" ").join("-");
        const product = {
          productTitle: this.newProduct.productTitle,
          productDescription: this.newProduct.productDescription,
          productImage: url,
          fileref: fileRef.fullPath,
          productPath: newText,
          productId: this.newProduct.productId,
          productImages: this.newProduct.productImages,
        };
        await this.$store.dispatch("UPDATE_PRODUCT", product);
        this.newProduct.display = "kapak resmi seçilmedi";
        this.newProduct.productTitle = "";
        this.newProduct.productDescription = "";
        this.newProduct.productImage = "";
        this.newProduct.productImages = [];
        this.productUpdateModal = false;
        this.progress = "";
        await this.$store.dispatch("GET_PRODUCTS_DATA");
        this.Products = this.$store.getters.GET_PRODUCTS;
      }
    },
    //##################################################### PRODUCT UPDATE END ####################################

    //##################################################### PRODUCT DELETE START ####################################

    async DELETE_PRODUCT(product) {
      let value = confirm(
        product.productTitle +
          " Adlı Ürünü Silmek İstediğinizden Emin Misiniz? "
      );
      if (value === true) {
        await db.collection("Products").doc(product.productId).delete();
        await storage.ref(product.fileref).delete();
        let images = product.productImages;
        if (images != "") {
          images.forEach(async (el) => {
            await storage.refFromURL(el).delete();
          });
        }
      }
      await this.$store.dispatch("GET_PRODUCTS_DATA");
      this.Products = this.$store.getters.GET_PRODUCTS;
    },
    //##################################################### PRODUCT DELETE END ####################################

    //##################################################### DELETE PRODUCT IMAGE START ####################################

    async DELETE_PRODUCT_IMAGE(img) {
      let value = confirm(" Görseli Silmek İstediğinizden Emin Misiniz? ");
      let newImageData = this.newProduct.productImages;
      const index = newImageData.indexOf(img);
      console.log(this.newProduct.productImages);
      if (value === true) {
        await storage.refFromURL(img).delete();
        newImageData.splice(index, 1);
      }
    },

    //##################################################### DELETE PRODUCT IMAGE END ####################################

    //##################################################### IMAGE GALLERY START ####################################
    handleFileImageGallery(e) {
      this.images.imageUrl = e.target.files[0];
    },
    async SAVE_IMAGE_GALLERY(e) {
      e.preventDefault();
      let id = Date.now().toString();
      let url = await this.handleFileUpload(
        this.images.imageUrl,
        "Resim Galeri"
      );
      let fileRef = await firebase.storage.refFromURL(url);
      const image = {
        imageUrl: url,
        fileref: fileRef.fullPath,
        imageId: id,
      };
      await this.$store.dispatch("POST_IMAGE_GALLERY", image);
      await this.$store.dispatch("GET_IMAGE_DATA");
      this.galleryImages = this.$store.getters.GET_IMAGES;
    },
    //##################################################### IMAGE GALLERY END ####################################

    //##################################################### IMAGE DELETE START ####################################

    async DELETE_IMAGE(img) {
      let value = confirm("Resmi Silmek İstediğinizden Emin Misiniz? ");
      if (value === true) {
        await db.collection("Resim Galeri").doc(img.imageId).delete();
        await storage.ref(img.fileref).delete();
        await this.$store.dispatch("GET_IMAGE_DATA");
        this.galleryImages = this.$store.getters.GET_IMAGES;
      }
    },
    //##################################################### IMAGE DELETE END ####################################

    //##################################################### SAVE VIDEO START ####################################

    async SAVE_VIDEO() {
      let video = this.videoLink;
      if (video != "") {
        let videoUrlId = video.split("v=")[1].substring(0, 11);
        let id = Date.now().toString();
        let videoData = {
          videoLink: videoUrlId,
          videoId: id,
        };
        await this.$store.dispatch("POST_VIDEO", videoData);
        this.video = "";
        await this.$store.dispatch("GET_VIDEO_DATA");
        this.Videos = this.$store.getters.GET_VIDEO;
      }
    },
    //##################################################### SAVE VIDEO END ####################################

    //##################################################### DELETE REFERENCE START ####################################

    async DELETE_VIDEO(video) {
      let value = confirm("Videoyu Silmek İstediğinizden Emin Misiniz? ");
      if (value === true) {
        await db.collection("Video Galeri").doc(video.videoId).delete();
        await this.$store.dispatch("GET_VIDEO_DATA");
        this.Videos = this.$store.getters.GET_VIDEO;
      }
    },

    //##################################################### DELETE REFERENCE END ####################################

    //##################################################### SAVE REFERENCE START ####################################

    async SAVE_REFERENCE() {
      let ref = this.reference;
      if (ref != "") {
        let id = Date.now().toString();
        let keyword = {
          reference: ref,
          referenceId: id,
        };
        await this.$store.dispatch("POST_REFERENCE", keyword);
        this.reference = "";
        await this.$store.dispatch("GET_REFERENCE_DATA");
        this.References = this.$store.getters.GET_REFERENCES;
      }
    },
    //##################################################### SAVE REFERENCE END ####################################

    //##################################################### DELETE REFERENCE START ####################################

    async DELETE_REFERENCE(ref) {
      let value = confirm("Referansı Silmek İstediğinizden Emin Misiniz? ");
      if (value === true) {
        await db.collection("Referanslar").doc(ref.referenceId).delete();
        await this.$store.dispatch("GET_REFERENCE_DATA");
        this.References = this.$store.getters.GET_REFERENCES;
      }
    },

    //##################################################### DELETE REFERENCE END ####################################

    //##################################################### SOCIAL MEDIA START ####################################
    async SAVE_SOCIAL_MEDIA() {
      let face = this.social.facebook;
      let insta = this.social.instagram;
      let twit = this.social.twitter;

      const Social = {
        facebook: face ? face : "#",
        instagram: insta ? insta : "#",
        twitter: twit ? twit : "#",
      };
      await this.$store.dispatch("POST_SOCIAL_MEDIA", Social);
    },
    //##################################################### SOCIAL MEDIA END ####################################

    //##################################################### SAVE KEYWORD START ####################################

    async SAVE_KEYWORD() {
      let key = this.keyword;
      if (key != "") {
        let id = Date.now().toString();
        let keyword = {
          keyword: key,
          keywordId: id,
        };
        await this.$store.dispatch("POST_KEYWORD", keyword);
        this.keyword = "";
        await this.$store.dispatch("GET_KEYWORDS_DATA");
        this.Keywords = this.$store.getters.GET_KEYWORDS;
      }
    },
    //##################################################### SAVE KEYWORD END ####################################

    //##################################################### DELETE KEYWORD START ####################################

    async DELETE_KEYWORD(key) {
      let value = confirm(
        "Anahtar Kelimeyi Silmek İstediğinizden Emin Misiniz? "
      );
      if (value === true) {
        await db.collection("Anahtar Kelimeler").doc(key.keywordId).delete();
        await this.$store.dispatch("GET_KEYWORDS_DATA");
        this.Keywords = this.$store.getters.GET_KEYWORDS;
      }
    },
    //##################################################### DELETE KEYWORD END ####################################
  },
};
</script>

<style>
.fa:hover {
  cursor: pointer;
}
.input-div {
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.input-div input {
  width: 100%;
  border-radius: 6px;
  padding: 5px;
}
.nav-pills button {
  margin: 0 4px;
}
</style>