import firebase from '../firebase/firebase'
let db = firebase.db;

// GET BLOG DATA FROM FİRESTORE //
export const GET_GENERAL_DATA = async (context) => {
  const comingData = [];
  await db
    .collection("Genel Bilgiler")
    .get()
    .then((result) => {
      result.forEach((doc) => {
        comingData.push(doc.data())
      });
      // console.log(comingData);
      context.commit('generalMutation', comingData)
    })
    .catch((err) => {
      alert("getGeneralData" + err);
    });
};

// GET PRODUCTS DATA FROM FİRESTORE //
export const GET_PRODUCTS_DATA = async (context) => {
  const comingProductsData = [];
  await db
    .collection("Products")
    .get()
    .then((result) => {
      result.forEach((doc) => {
        comingProductsData.push(doc.data())
      });
      // console.log(comingData);
      context.commit('productsMutation', comingProductsData)
    })
    .catch((err) => {
      alert("getProductData" + err);
    });
};

// GET Image DATA FROM FİRESTORE //
export const GET_IMAGE_DATA = async (context) => {
  const comingImagesData = [];
  await db
    .collection("Resim Galeri")
    .get()
    .then((result) => {
      result.forEach((doc) => {
        comingImagesData.push(doc.data())
      });
      // console.log(comingData);
      context.commit('imagesMutation', comingImagesData)
    })
    .catch((err) => {
      alert("getImageData" + err);
    });
};

// GET SLIDE DATA FROM FİRESTORE //
export const GET_SLIDE_DATA = async (context) => {
  const comingSlideData = [];
  await db
    .collection("Slide")
    .get()
    .then((result) => {
      result.forEach((doc) => {
        comingSlideData.push(doc.data())
      });
      // console.log(comingData);
      context.commit('slidesMutation', comingSlideData)
    })
    .catch((err) => {
      alert("getSlideData" + err);
    });
};
//POST GENERAL INFOS TO FIRESTORE
export const POST_GENERAL_INFOS = async (context, GeneralData) => {
  console.log("postPortfolio data active");
  await db.collection("Genel Bilgiler").doc("Genel").set(GeneralData)
};

//POST LOGO TO FIRESTORE
export const POST_LOGO = async (context, logo) => {
  console.log("logo update active");
  await db.collection("Genel Bilgiler").doc("Logo").set(logo)
};

//POST SLIDE TO FIRESTORE
export const POST_SLIDE = async (context, slide) => {
  console.log("slide data active");
  let id = slide.slideId
  await db.collection("Slide").doc(id).set(slide)
};

//UPDATE SLIDE TO FIRESTORE
export const UPDATE_SLIDE = async (context, slide) => {
  console.log("UPDATE slide data active");
  let id = slide.slideId
  await db.collection("Slide").doc(id).update(slide)
};

//POST ABOUT US TO FIRESTORE
export const UPDATE_ABOUT_US = async (context, aboutUs) => {
  console.log("about us update active");
  await db.collection("Genel Bilgiler").doc("Hakkimizda")
    .set(aboutUs)
}

// POST PRODUCT DATA TO FİRESTORE //
export const CREATE_PRODUCT = async (context, product) => {
  console.log("product create data active")
  let id = product.productId
  await db.collection("Products").doc(id).set(product)
}

// UPDATE PRODUCT DATA TO FİRESTORE //
export const UPDATE_PRODUCT = async (context, product) => {
  console.log("product UPDATE data active")
  let id = product.productId
  await db.collection("Products").doc(id).update(product)
}

// POST IMAGE DATA TO FİRESTORE //
export const POST_IMAGE_GALLERY = async (context, image) => {
  console.log("image data active")
  let id = image.imageId
  await db.collection("Resim Galeri").doc(id).set(image)
}

// POST VIDEO DATA TO FİRESTORE //
export const POST_VIDEO = async (context, video) => {
  console.log("video data active")
  let id = video.videoId
  await db.collection("Video Galeri").doc(id).set(video)
}

// GET VIDEO DATA FROM FİRESTORE //
export const GET_VIDEO_DATA = async (context) => {
  const comingVideoData = [];
  await db
    .collection("Video Galeri")
    .get()
    .then((result) => {
      result.forEach((doc) => {
        comingVideoData.push(doc.data())
      });
      context.commit('videosMutation', comingVideoData)
    })
    .catch((err) => {
      alert("getVideosData" + err);
    });
};

// POST REFERENCE DATA TO FİRESTORE //
export const POST_REFERENCE = async (context, reference) => {
  console.log("reference data active")
  let id = reference.referenceId
  await db.collection("Referanslar").doc(id).set(reference)
}

// GET Reference DATA FROM FİRESTORE //
export const GET_REFERENCE_DATA = async (context) => {
  const comingReferenceData = [];
  await db
    .collection("Referanslar")
    .get()
    .then((result) => {
      result.forEach((doc) => {
        comingReferenceData.push(doc.data())
      });
      context.commit('referencesMutation', comingReferenceData)
    })
    .catch((err) => {
      alert("getReferencesData" + err);
    });
};

// POST SOCIAL MEDIA DATA TO FİRESTORE //
export const POST_SOCIAL_MEDIA = async (context, social) => {
  console.log("social data active")
  await db.collection("Social Media").doc("Social").set(social)
}

// GET SOCIAL MEDIA DATA FROM FİRESTORE //
export const GET_SOCIAL_DATA = async (context) => {
  const comingSocialData = [];
  await db
    .collection("Social Media")
    .get()
    .then((result) => {
      result.forEach((doc) => {
        comingSocialData.push(doc.data())
      });
      context.commit('soacialMutation', comingSocialData)
    })
    .catch((err) => {
      alert("getSocialData" + err);
    });
};

// POST KEYWORD DATA TO FİRESTORE //
export const POST_KEYWORD = async (context, keyword) => {
  console.log("keyword data active")
  let id = keyword.keywordId
  await db.collection("Anahtar Kelimeler").doc(id).set(keyword)
}

// GET SLIDE DATA FROM FİRESTORE //
export const GET_KEYWORDS_DATA = async (context) => {
  const comingKeywordsData = [];
  await db
    .collection("Anahtar Kelimeler")
    .get()
    .then((result) => {
      result.forEach((doc) => {
        comingKeywordsData.push(doc.data())
      });
      context.commit('keywordsMutation', comingKeywordsData)
    })
    .catch((err) => {
      alert("getKeywordsData" + err);
    });
};