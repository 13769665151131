import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from "../firebase/firebase"
import Login from '../views/Login.vue'
import Admin from '../views/Admin/Admin.vue'
import Product from '../views/Products.vue'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Portfalio from '../views/Image-Gallery.vue'
import VideoGallery from '../views/Video-Gallery.vue'
import productDetail from '../views/Product_Detail.vue'
import References from '../views/References.vue'

Vue.use(VueRouter);

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true
        }

    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/admin-panel',
        name: 'AdminPanel',
        component: Admin,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/urunler',
        name: 'Product',
        component: Product,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/urunler/:productId',
        name: 'productDetail',
        component: productDetail,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/iletisim',
        name: 'Contact',
        component: Contact,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/resim-galeri',
        name: 'Portfalio',
        component: Portfalio,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/video-galeri',
        name: 'Video',
        component: VideoGallery,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/hakkimizda',
        name: 'About',
        component: About,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/referanslar',
        name: 'References',
        component: References,
        meta: {
            requiresAuth: true
        }
    }
]
const router = new VueRouter({
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        };
    },
    //mode: 'history',
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const data = firebase.firebase;
    const user = data.auth().currentUser;
    if (requiresAuth && !user) {
        next("/login")
    } else {
        next();
    }

})

export default router