<template>
  <div>
    <div class="container text-center login">
      <form
        @submit.prevent="pressed"
        class="loginForm text-center container col-md-6"
      >
        <img src="@/assets/ultof-logo.png" class="w-25 mt-5" alt=" LOGO" />
        <div class="login mt-3">
          <input
            class="form-control"
            type="email"
            placeholder="E-Mail Address"
            v-model="email"
          />
        </div>
        <div class="login mt-2">
          <input
            class="form-control"
            type="password"
            placeholder="Password"
            v-model="password"
          />
        </div>
        <div class="login mt-2">
          <input
            class="form-control"
            type="password"
            placeholder="Security Key"
            v-model="securityKey"
          />
        </div>
        <button class="btn btn-danger mt-3 mb-5 loginbtn" type="submit">
          <i class="fas fa-sign-in-alt"></i>
          Login
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import firebase from "../firebase/firebase";
import router from "../Router/index";
export default {
  data() {
    return {
      email: "",
      password: "",
      securityKey: "",
      error: "",
    };
  },
  methods: {
    async pressed() {
      if (this.email == "" || this.password == "" || this.securityKey == "") {
        this.showAlert("danger", "Fill The Blanks");
      } else {
        const key = "mabcodes";
        const enteredKey = this.securityKey.trim();
        if (enteredKey === key) {
          try {
            var data = firebase.firebase;
            await data
              .auth()
              .signInWithEmailAndPassword(this.email, this.password);
            router.replace({ name: "AdminPanel" });
          } catch (err) {
            this.showAlert(
              "danger",
              "There is no user record corresponding to this identifier"
            );
          }
        } else this.showAlert("danger", "Security Key Error");
      }
    },
    showAlert(value, message) {
      const form = document.querySelector("form");
      const alertDiv = document.createElement("div");
      alertDiv.style.display = "flex";
      alertDiv.style.position = "absolute";
      const a = document.createElement("a");
      a.className = `alert alert-${value}`;
      a.innerText = message;
      alertDiv.appendChild(a);
      form.appendChild(alertDiv);
      setTimeout(() => {
        alertDiv.remove();
      }, 2000);
    },
  },
};
</script>
<style>
/* THIS SECTION ONLY FOR LOGIN PAGE*/
.loginForm {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12%;
  margin-bottom: 12%;
  background-color: #85ffbd;
  background-image: linear-gradient(45deg, #85ffbd 0%, #fffb7d 100%);
  border-radius: 8px;
}

.loginForm .login input {
  border-radius: 12px;
  width: 300px;
  height: 40px;
  line-height: 2rem;
  background: transparent;
}
</style>