<template>
  <div class="container pt-5 pb-5">
    <div id="portfolio" class="portfolio">
      <div class="section-title">
        <h2>Galeri</h2>
        <p>VİDEO GALERİ</p>
      </div>
    </div>

    <section class="portfolio-section loadmore-gallery-one portfolio-mixitup">
      <div class="container">
        <div v-if="Videos.length > 0" class="row col-lg-12">
          <div
            v-for="video in Videos"
            :key="video.videoId"
            class="p-1 col-lg-4 col-md-6 col-sm-12"
          >
            <iframe
              class="video-iframe"
              allowfullscreen
              :src="
                'https://www.youtube.com/embed/' +
                video.videoLink +
                '?autoplay=0&mute=1'
              "
            >
            </iframe>
          </div>
        </div>
      </div>
    </section>

    <Scroll />
  </div>
</template>

<script>
//import firebase from "../firebase/firebase";
import Scroll from "../components/ScrollToTop.vue";

//let db = firebase.db;

export default {
  data() {
    return {
      Videos: [],
    };
  },
  components: {
    Scroll,
  },
  async created() {
    await this.$store.dispatch("GET_VIDEO_DATA");
    this.Videos = this.$store.getters.GET_VIDEO;
    await this.scripts();
  },
  methods: {
    async scripts() {
      let customscript2 = document.createElement("script");
      customscript2.setAttribute("src", "/assets/js/mixitup.js");
      document.head.appendChild(customscript2);
    },
  },
};
</script>
 <style>
@media (min-width: 1100px) {
  .video-iframe {
    width: 340px;
    height: 300px;
  }
}
</style>