<template>
  <div class="container pt-5 pb-5">
    <div id="about" class="about">
      <div class="section-title">
        <h2>Hakkımızda</h2>
        <p>KISA TARİHÇE</p>
      </div>
      <div class="w-100">
        <p v-html="aboutUs.about"></p>
      </div>
    </div>
    <!-- End About Me -->

    <Scroll />
  </div>
</template>

<script>
import Scroll from "../components/ScrollToTop.vue";
export default {
  name: "About",
  components: {
    Scroll,
  },
  data() {
    return {
      aboutUs: {
        about: "",
      },
    };
  },
  async created() {
    await this.$store.dispatch("GET_GENERAL_DATA");
    this.gelenData = this.$store.getters.GET_GENERAL_INFOS;
    this.aboutUs = this.gelenData[1];
  },
  methods: {},
};
</script>

<style>
</style>