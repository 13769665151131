<template>
  <div class="container pt-5 pb-5">
    <div id="about" class="about">
      <div class="section-title">
        <h2>Referanslar</h2>
        <p>BİZİ TERCİH Eden İş Ortaklarımız</p>
      </div>
      <div class="w-100 references">
        <div v-for="ref in References" :key="ref.referenceId">
          <p>{{ ref.reference }}</p>
        </div>
      </div>
    </div>

    <Scroll />
  </div>
</template>

<script>
import Scroll from "../components/ScrollToTop.vue";
export default {
  name: "References",
  components: {
    Scroll,
  },
  data() {
    return {
      References: [],
    };
  },
  async created() {
    await this.$store.dispatch("GET_REFERENCE_DATA");
    this.References = this.$store.getters.GET_REFERENCES;
    this.References.sort((a, b) => {
      return b.referenceId - a.referenceId;
    });
  },
  methods: {},
};
</script>

<style>
.references {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.references div {
  background-color: antiquewhite;
  margin: 5px;
  border-radius: 6px;
}
.references div p {
  font-family: "Dancing Script", cursive;
  font-size: 1em;
  font-weight: 700;
  padding: 15px 30px;
  margin: 0;
}
</style>