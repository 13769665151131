<template>
  <div>
    <div class="container pt-5 pb-5">
      <div class="section-title">
        <h2>Ürünler</h2>
        <p>ULTOF ÜRÜNLERİ</p>
      </div>
      <div class="my-5 text-center">
        <div class="row">
          <div
            class="col-lg-4 col-md-6 col-sm-12"
            v-for="(product, index) in Products"
            :key="index"
          >
            <div
              class="card mb-5 shadow-sm"
              style="height: 470px; overflow: hidden"
            >
              <img
                id="blogImage"
                :src="product.productImage"
                @click="detayagit(product)"
                style="cursor: pointer; height: 220px; object-fit: cover"
              />
              <div class="card-body">
                <div
                  class="card-title"
                  @click="detayagit(product)"
                  style="cursor: pointer"
                >
                  <h2
                    v-html="product.productTitle.slice(0, 36) + '..'"
                    style="font-size: 20px; font-weight: 600"
                  ></h2>
                </div>
                <div class="card-text">
                  <p
                    v-html="product.productDescription.slice(0, 100) + '..'"
                  ></p>
                </div>
                <a
                  @click="detayagit(product)"
                  class="btn-me rounded-2"
                  style="cursor: pointer"
                  >Ürünü İncele</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <Scroll />
    </div>
  </div>
</template>

<script>
import Scroll from "../components/ScrollToTop.vue";
//let db = firebase.db;
export default {
  data() {
    return {
      Products: [],
    };
  },
  components: {
    Scroll,
  },
  computed: {},
  async created() {
    await this.$store.dispatch("GET_PRODUCTS_DATA");
    this.Products = this.$store.getters.GET_PRODUCTS;
    this.Products.sort((a, b) => {
      return b.productId - a.productId;
    });
    //console.log(this.Products);
  },
  methods: {
    detayagit(id) {
      this.$router.push("/urunler/" + id.productPath);
    },
  },
};
</script>

 <style>
</style>