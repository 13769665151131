export const generalMutation = (state, GeneralInfos) => {
    state.GeneralInfos = GeneralInfos;
}
export const productsMutation = (state, products) => {
    state.Products = products;
}
export const imagesMutation = (state, images) => {
    state.Images = images;
}
export const slidesMutation = (state, slides) => {
    state.Slides = slides;
}
export const keywordsMutation = (state, keywords) => {
    state.Keywords = keywords;
}
export const referencesMutation = (state, references) => {
    state.References = references;
}
export const videosMutation = (state, videos) => {
    state.Videos = videos;
}
export const soacialMutation = (state, social) => {
    state.Social = social;
}