<template>
  <div class="container pt-5 pb-5">
    <div id="portfolio" class="portfolio">
      <div class="section-title">
        <h2>Galeri</h2>
        <p>RESİM GALERİ</p>
      </div>
      <!--  <div class="row">
        <div class="col-lg-12 d-flex justify-content-center">
          <ul id="portfolio-flters">
            <li data-filter="*" class="filter-active">All</li> 
          </ul>
        </div>
      </div>-->
    </div>

    <section class="portfolio-section loadmore-gallery-one portfolio-mixitup">
      <div class="container">
        <div v-if="Resimler.length > 0" class="mixit-gallery filter-gallery">
          <div class="filter-gallery-one row clearfix">
            <div
              v-for="(resim, index) in Resimler"
              :key="index"
              class="
                gallery-block
                mix
                all
                tour
                industry
                col-lg-4 col-md-6 col-sm-12
              "
            >
              <div
                class="inner-box wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div class="image-box">
                  <figure class="image">
                    <img
                      :src="resim.imageUrl"
                      alt
                      style="height: 350px; object-fit: cover"
                    />
                  </figure>

                  <div class="zoom-btn">
                    <a
                      class="lightbox-image zoom-link"
                      :href="resim.imageUrl"
                      target="_blank"
                      data-fancybox="gallery"
                    >
                      <span> <i class="fa fa-search-plus"></i></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Scroll />
  </div>
</template>

<script>
//import firebase from "../firebase/firebase";
import Scroll from "../components/ScrollToTop.vue";

//let db = firebase.db;

export default {
  data() {
    return {
      Resimler: [],
    };
  },
  components: {
    Scroll,
  },
  async created() {
    await this.$store.dispatch("GET_IMAGE_DATA");
    this.Resimler = this.$store.getters.GET_IMAGES;
    await this.scripts();
  },
  methods: {
    async scripts() {
      let customscript2 = document.createElement("script");
      customscript2.setAttribute("src", "/assets/js/mixitup.js");
      document.head.appendChild(customscript2);
    },
  },
};
</script>
 <style>
h1 {
  font-size: 50px;
  font-weight: 600 !important;
  padding: 10px;
  /* background-color: #060c1f; */
  color: #000;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.left-h1 {
  font-size: 50px;
  font-weight: 600 !important;
  color: #000;
  padding: 0 !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
</style>