<template>
  <div class="container pt-5 pb-5">
    <div id="contact" class="contact">
      <div class="section-title">
        <h2>İletişim</h2>
        <p>BİZİMLE İLETİŞİME GEÇ</p>
      </div>
      <div class="row mt-2">
        <div class="col-md-6 d-flex align-items-stretch">
          <div class="info-box">
            <i class="bx bx-map"></i>
            <h3>Adres</h3>
            <p>{{ this.siteBilgileri.address }}</p>
          </div>
        </div>
        <div class="col-md-6 mt-4 mt-md-0 d-flex align-items-stretch">
          <div class="info-box">
            <i class="bx bx-share-alt"></i>
            <h3>Sosyal Medya</h3>
            <div class="social-links-contact">
              <a
                :href="this.$store.state.Social[0].facebook"
                class="facebook"
                target="_blank"
                ><i class="bi bi-facebook"></i
              ></a>
              <a
                :href="this.$store.state.Social[0].instagram"
                class="instagram"
                target="_blank"
                ><i class="bi bi-instagram"></i></a
              ><a
                :href="this.$store.state.Social[0].twitter"
                class="twitter"
                target="_blank"
                ><i class="bi bi-twitter"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-4 d-flex align-items-stretch">
          <div class="info-box">
            <i class="bx bx-envelope"></i>
            <h3>E-Mail Adresi</h3>
            <p>{{ this.siteBilgileri.mail }}</p>
          </div>
        </div>
        <div class="col-md-6 mt-4 d-flex align-items-stretch">
          <div class="info-box">
            <i class="bx bx-phone-call"></i>
            <h3>Telefon</h3>
            <p>
              {{ this.siteBilgileri.phone }}
            </p>
          </div>
        </div>
      </div>

      <form @submit.prevent="sendMail" class="php-email-form mt-4">
        <div class="row">
          <div class="col-md-6 form-group">
            <input
              type="text"
              name="name"
              class="form-control"
              placeholder="İsim"
              v-model="mailInfo.name"
              required
            />
          </div>
          <div class="col-md-6 form-group mt-3 mt-md-0">
            <input
              type="email"
              class="form-control"
              name="email"
              placeholder="Email"
              v-model="mailInfo.mail"
              required
            />
          </div>
        </div>
        <div class="form-group mt-3">
          <input
            type="text"
            class="form-control"
            name="subject"
            placeholder="Konu"
            v-model="mailInfo.subject"
            required
          />
        </div>
        <div class="form-group mt-3">
          <textarea
            class="form-control"
            name="message"
            rows="5"
            placeholder="Mesaj"
            v-model="mailInfo.message"
            required
          ></textarea>
        </div>
        <!-- <div class="my-3">
          <div class="loading">Loading</div>
          <div class="error-message"></div>
          <div class="sent-message">Your message has been sent. Thank you!</div>
        </div> -->
        <div class="text-center" id="sendButton">
          <button type="submit">GÖNDER</button>
        </div>
      </form>
      <div class="mt-5 mb-5">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3190.5959879231177!2d30.64366681498693!3d36.90001226977507!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c3912dad8782cf%3A0x39f47f58b1ca6392!2zVUxUT0YgWWF6xLFsxLFt!5e0!3m2!1sen!2str!4v1670245014562!5m2!1sen!2str"
          width="100%"
          height="450"
          style="border: 0; border-radius: 8px"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
    <Scroll />
  </div>
</template>

<script>
import Scroll from "../components/ScrollToTop.vue";
import emailjs from "emailjs-com";

export default {
  name: "Contact",
  data() {
    return {
      mailInfo: {
        name: "",
        mail: "",
        subject: "",
        message: " ",
      },
      siteBilgileri: {
        title: "",
        phone: "",
        mail: "",
        address: "",
      },
      gelenData: [],
    };
  },
  components: {
    Scroll,
  },
  async created() {
    await this.$store.dispatch("GET_GENERAL_DATA");
    this.gelenData = this.$store.getters.GET_GENERAL_INFOS;
    this.siteBilgileri = this.gelenData[0];
  },
  methods: {
    sendMail(e) {
      e.preventDefault();
      try {
        emailjs.sendForm("", "", e.target, "", {
          name: this.mailInfo.name,
          email: this.mailInfo.mail,
          subject: this.mailInfo.subject,
          message: this.mailInfo.message,
        });
        this.showInfo("Mail Gönderildi");
      } catch (error) {
        console.log({ error });
      }
      // Reset form field
      this.mailInfo.name = "";
      this.mailInfo.mail = "";
      this.mailInfo.subject = "";
      this.mailInfo.message = "";
    },
    showInfo(message) {
      event.preventDefault();
      const a = document.getElementById("sendButton");
      a.innerText = message;
    },
  },
};
</script>

<style>
</style>