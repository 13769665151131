<template>
  <div class="container-fluid pb-5">
    <router-view />
    <div class="container pt-5 pb-5 product-section">
      <div class="section-title">
        <h2>ÜRÜN</h2>
        <p class="product-title">{{ productDetail.productTitle }}</p>
      </div>
      <div class="my-5 mx-0">
        <div class="row col-lg-12 product-detail">
          <div class="col col-lg-6 col-md-12 col-sm-12">
            <img class="img mt-2" :src="productDetail.productImage" />
          </div>
          <div class="col col-lg-6 mobil-detail">
            <h2 class="product-h2">Ürün Detayları</h2>
            <p
              v-html="productDetail.productDescription"
              style="padding-top: 2%; font-size: 20px; color: #000"
            ></p>
          </div>
        </div>
        <div class="portfolio-section loadmore-gallery-one portfolio-mixitup">
          <div class="container-fluid">
            <div class="mixit-gallery filter-gallery">
              <div class="filter-gallery-one row clearfix mt-5">
                <div class="w-100 text-center">
                  <h2
                    v-if="productDetail.productImages.length > 0"
                    class="product-h2"
                    style="text-decoration: underline; font-weight: 600"
                  >
                    Ürün Galeri
                  </h2>
                </div>
                <div
                  v-for="image in productDetail.productImages"
                  :key="image"
                  class="
                    gallery-block
                    mix
                    all
                    tour
                    industry
                    col-lg-3 col-md-6 col-sm-12
                  "
                >
                  <div
                    class="inner-box wow fadeInUp"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div class="image-box mt-5">
                      <figure class="image">
                        <img
                          :src="image"
                          style="height: 300px; object-fit: cover"
                        />
                      </figure>

                      <div class="zoom-btn">
                        <a
                          class="lightbox-image zoom-link"
                          :href="image"
                          target="_blank"
                          data-fancybox="gallery"
                        >
                          <span> <i class="fa fa-search-plus"></i></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Scroll />
  </div>
</template>

<script>
import Scroll from "../components/ScrollToTop.vue";
export default {
  data() {
    return {
      Products: [],
      productDetail: {},
    };
  },
  components: {
    Scroll,
  },
  async created() {
    // const ID = this.$route.params.productId;
    // console.log(ID);
    await this.$store.dispatch("GET_PRODUCTS_DATA");
    this.Products = this.$store.getters.GET_PRODUCTS;

    this.Products.forEach((el) => {
      if (el.productPath == this.$route.params.productId) {
        this.productDetail = el;
      }
    });
  },
  methods: {},
};
</script>

<style>
</style>