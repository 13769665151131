<template>
  <div>
    <header class="site-navbar" role="banner">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-11 col-xl-2">
            <h1 class="mb-0 site-logo">
              <!-- <a href="/" class="text-white mb-0">ULTOF</a> -->
              <router-link tag="a" to="/">
                <img :src="logo.logo" alt=""
              /></router-link>
            </h1>
          </div>
          <div class="col-12 col-md-10 d-xl-block">
            <nav
              class="site-navigation position-relative text-right"
              role="navigation"
            >
              <input type="checkbox" id="click" />
              <label for="click" class="menu-btn">
                <i class="fa fa-bars"></i>
              </label>
              <ul class="site-menu js-clone-nav mr-auto d-lg-block">
                <li>
                  <router-link to="/" tag="a" active-class="site-menu-active"
                    ><span>Anasayfa</span></router-link
                  >
                </li>
                <li>
                  <router-link
                    tag="a"
                    to="/urunler"
                    active-class="site-menu-active"
                    ><span>Ürünlerimiz</span></router-link
                  >
                </li>
                <li class="has-children">
                  <router-link
                    tag="a"
                    to="/resim-galeri"
                    active-class="site-menu-active"
                    ><span>Galeri ⤾</span></router-link
                  >
                  <ul class="dropdown">
                    <li>
                      <router-link tag="a" to="/resim-galeri"
                        >Resim</router-link
                      >
                    </li>
                    <li>
                      <router-link tag="a" to="/video-galeri"
                        >Video</router-link
                      >
                    </li>
                  </ul>
                </li>
                <li>
                  <router-link
                    tag="a"
                    to="/referanslar"
                    active-class="site-menu-active"
                  >
                    <span>Referanslar</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    tag="a"
                    to="/hakkimizda"
                    active-class="site-menu-active"
                  >
                    <span>Hakkımızda</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    tag="a"
                    to="/iletisim"
                    active-class="site-menu-active"
                    ><span>İletişim</span>
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      path: "",
      logo: {},
    };
  },
  async created() {
    await this.$store.dispatch("GET_GENERAL_DATA");
    this.logo = this.$store.getters.GET_GENERAL_INFOS[2];
  },
  methods: {},
};
</script>

 <style>
.site-menu-active span {
  background: #000;
  color: #fff;
  border-radius: 30px;
  display: inline-block;
  padding: 5px 20px;
}
</style>