// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

//import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCsP3ZhVTvfQlYBzAR74Y_bJOsWk4NVHdE",
  authDomain: "ultof-admin-panel.firebaseapp.com",
  projectId: "ultof-admin-panel",
  storageBucket: "ultof-admin-panel.appspot.com",
  messagingSenderId: "616449625845",
  appId: "1:616449625845:web:63455fc0c87f0736bcffbc"

};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
const auth = firebase.auth()
const db = firebase.firestore()
const storage = firebase.storage()

export default {
  firebase,
  auth,
  db,
  storage
}