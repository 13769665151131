<template>
  <div>
    <!-- Carousel Section Start -->
    <div
      id="carouselExampleCaptions"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/ultof-admin-panel.appspot.com/o/wepik-202237-143640.png?alt=media&token=4ab25dbe-824e-4952-a7ef-94fbb7474300"
            class="d-block w-100"
          />
          <div class="carousel-caption d-none d-md-block">
            <h5>ULTOF Yazılım</h5>
            <p>Web Sayfamıza Hoşgeldiniz</p>
            <router-link tag="a" to="/urunler" class="btn-me-fill"
              >Ürünlerimizi İncele</router-link
            >
          </div>
        </div>
        <div v-for="slide in Slides" :key="slide.slideId" class="carousel-item">
          <img :src="slide.slideImage" />
          <div class="carousel-caption d-none d-md-block">
            <h5 style="font-size: 22px; font-weight: 600">
              {{ slide.slideTitle }}
            </h5>
            <span v-html="slide.slideDescription"></span>
            <a :href="slide.slideLink" class="btn-me-fill">Bilgi Al</a>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden"></span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden"></span>
      </button>
    </div>
    <!-- Carousel Section End -->

    <!-- ################################################################### -->

    <!-- Short About Us Section Start -->
    <div class="container-fluid mt-5">
      <div class="row home-about-us">
        <div class="col col-lg-6 img-div">
          <img :src="Slides[0].slideImage" class="img" />
        </div>
        <div class="col col-lg-6 p-5">
          <h1 class="left-h1">{{ siteGenelBilgiler.title }}</h1>
          <p v-html="aboutUs.about"></p>
          <div class="social">
            <a :href="this.$store.state.Social[0].facebook" target="_blank"
              ><i class="fab fa-facebook"></i
            ></a>
            <a :href="this.$store.state.Social[0].instagram" target="_blank"
              ><i class="fab fa-instagram"></i></a
            ><a :href="this.$store.state.Social[0].twitter" target="_blank"
              ><i class="fab fa-twitter"></i
            ></a>
          </div>
          <div class="mt-3">
            <router-link tag="a" to="/iletisim" class="btn-me"
              >Bizimle İletişime Geç</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <!-- Short About Us Section End -->

    <!-- ################################################################### -->

    <!-- Products Section Start -->
    <div class="container product-section">
      <h1 class="text-center mt-5">Ürünlerimizi İncele ✔</h1>
      <div class="my-4 text-center">
        <div class="row">
          <div
            class="col-lg-4 col-md-6 col-sm-12"
            v-for="(product, index) in Products"
            :key="index"
          >
            <div
              class="card mb-5 shadow-sm"
              style="height: 470px; overflow: hidden"
            >
              <img
                class="img-fluid"
                id="blogImage"
                :src="product.productImage"
                @click="detayagit(product)"
                style="cursor: pointer; height: 220px; object-fit: cover"
              />
              <div class="card-body">
                <div
                  class="card-title"
                  @click="detayagit(product)"
                  style="cursor: pointer"
                >
                  <h2
                    v-html="product.productTitle.slice(0, 36) + '..'"
                    style="font-size: 20px; font-weight: 600"
                  ></h2>
                </div>
                <div class="card-text">
                  <p
                    v-html="product.productDescription.slice(0, 100) + '..'"
                  ></p>
                </div>
                <a
                  @click="detayagit(product)"
                  class="btn-me rounded-2"
                  style="cursor: pointer"
                  >Ürünü İncele</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Products Section End -->

    <!-- ################################################################### -->

    <!-- IFRAME Section Start -->
    <div class="container contact-section mb-5">
      <h1 class="text-center my-2">Bizimle İletişime Geç 📞</h1>
      <div class="row py-5 contact-box">
        <div class="col">
          <li>
            <a href=""><i class="fa fa-address-book"></i></a>
          </li>
          <li>
            <p>
              {{ siteGenelBilgiler.address }}
            </p>
          </li>
        </div>
        <div class="col">
          <li>
            <a href=""><i class="fa fa-envelope"></i></a>
          </li>
          <li>
            <p>{{ siteGenelBilgiler.mail }}</p>
          </li>
        </div>
        <div class="col">
          <li>
            <a href=""><i class="fa fa-phone"></i></a>
          </li>
          <li>
            <p>
              {{ siteGenelBilgiler.phone }}
            </p>
          </li>
        </div>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3190.5959879231177!2d30.64366681498693!3d36.90001226977507!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c3912dad8782cf%3A0x39f47f58b1ca6392!2zVUxUT0YgWWF6xLFsxLFt!5e0!3m2!1sen!2str!4v1670245014562!5m2!1sen!2str"
        width="100%"
        height="450"
        style="border: 0; border-radius: 8px"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    <!-- IFRAME Section End -->
    <Scroll />
  </div>
</template>
<script>
import Scroll from "../components/ScrollToTop.vue";

export default {
  name: "Home",
  components: { Scroll },
  data() {
    return {
      Slides: [],
      siteGenelBilgiler: {},
      aboutUs: {},
      Products: [],
    };
  },
  async created() {
    await this.$store.dispatch("GET_GENERAL_DATA");
    await this.$store.dispatch("GET_PRODUCTS_DATA");
    await this.$store.dispatch("GET_IMAGE_DATA");
    await this.$store.dispatch("GET_SLIDE_DATA");
    this.Slides = this.$store.getters.GET_SLIDE;
    this.siteGenelBilgiler = this.$store.getters.GET_GENERAL_INFOS[0];
    this.aboutUs = this.$store.getters.GET_GENERAL_INFOS[1];
    this.Products = this.$store.getters.GET_PRODUCTS;
    this.Products.sort(function (a, b) {
      return b.productId - a.productId;
    });
  },
  methods: {
    detayagit(id) {
      this.$router.push("/urunler/" + id.productPath);
    },
  },
};
</script>
<style>
.contact-section li {
  list-style: none;
  text-align: center;
}
.contact-section li a {
  font-size: 30px;
  color: #e4772f;
  border: 2px solid #e4772f;
  border-radius: 20%;
  padding: 20px;
}
.contact-section li p {
  font-size: 18px;
  margin-top: 30px;
  font-weight: 600;
  padding: 0;
}
.social a {
  font-size: 25px;
  color: #e4772f;
  padding: 10px;
}
</style>